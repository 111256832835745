import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const AshIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page ash'} game="ash">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Ash Echoes wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Ash Echoes Global - a gacha game published by{' '}
          <OutboundLink href="https://ae.neocraftstudio.com/en" target="_blank">
            Neocraft
          </OutboundLink>
          . Check our guides, tier lists and reviews for characters available in
          the game.
        </h2>
        <p>For the official Ash Echoes Global website go here:</p>
        <OutboundLink href="https://ae.neocraftstudio.com/en" target="_blank">
          <StaticImage
            src="../../images/ash/neocraft_logo.webp"
            alt="A-Level"
            className="partner-logo"
          />
        </OutboundLink>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Ash Echoes Database is currently being created!</h4>
          <p>
            We're currently gathering all necessary information for the upcoming
            launch!
          </p>
        </div>
      </div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/ash-echoes/characters"
          image={
            <StaticImage
              src="../../images/ash/categories/category_characters.webp"
              alt="Characters"
            />
          }
          isNew
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AshIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Ash Echoes Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Ash Echoes. Check our guides, tier lists and reviews for characters available in the game."
    game="ash"
  />
);
